<template>
  <div id="prices-our-policy">
    <div class="container">
      <div class="row justify-content-center headline-l title">
        {{ $t("pricesScreen.ourPolicy.title") }}
      </div>
      <div class="row boxes-container">
        <div class="col-12 col-md-6 box">
          <img class="icon" src="/images/icons/infinite.svg" />
          <p class="text headline">
            {{ $t("pricesScreen.ourPolicy.text1") }}
          </p>
        </div>
        <div class="col-12 col-md-6 box">
          <img class="icon" src="/images/icons/transparent.svg" />
          <p class="text headline">
            {{ $t("pricesScreen.ourPolicy.text2") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pricesOurPolicy",
  props: {},
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#prices-our-policy {
  .icon {
    margin: auto;
  }
  .title {
    color: $primary-purple;
    line-height: 60px;
    font-weight: bold;
    margin-bottom: $margin-l;
  }
  .boxes-container {
    .box {
      text-align: center;
      .text {
        margin-top: 10px;
        color: #262a52;
      }
    }
  }
}
@media (max-width: $breaking-point-to-sm) {
  .boxes-container {
    flex-direction: column;
    align-items: center;
    .box:first-of-type {
      margin-bottom: $margin-m;
    }
  }
}
</style>
