<template>
  <div id="prices-description">
    <div class="container">
      <div class="row">
        <div class="left-side col-12 col-md-6">
          <ul class="pl-0 m-0" style="list-style-type:none">
            <li
              class="text"
              v-for="index in descriptionTextCounts.left"
              :key="index"
            >
              {{ $t(`pricesScreen.description.leftSideTexts.${index}`) }}
            </li>
          </ul>
        </div>
        <div class="right-side col-12 col-md-6">
          <ul class="m-0"  style="list-style-type:none">
            <li
              class="text"
              v-for="index in descriptionTextCounts.right"
              :key="index"
            >
              {{ $t(`pricesScreen.description.rightSideTexts.${index}`) }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PricesDescription",
  props: {},
  components: {},
  data: () => ({
    descriptionTextCounts: {
      left: 9,
      right: 7,
    },
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#prices-description {
  margin-bottom: $margin-m;
  .text {
    font-size: $text-s;
    line-height: 20px;
    color: $text-blue;
  }
  .left-side {
    ul {
      padding-right: 15px;
    }
  }
  .right-side {
    ul {
      padding-left: 15px;
    }
  }
  .left-side,
  .right-side {
    li {
      margin: 0 0 25px;
    }
  }
}
@media (max-width: $breaking-point-to-sm) {
  .left-side {
    ul {
      padding-right: 0 !important;
    }
  }
  .right-side {
    ul {
      padding-left: 0 !important;
    }
  }
}
</style>
