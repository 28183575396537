<template>
  <div id="prizes-welcome-component">
    <div class="container">
      <accordion
        class="accordion"
        v-for="(accordion, index) in accordions"
        :key="index"
        :maxHeight="calcMaxHeight(index)"
      >
        <template v-slot:header>
          <div class="header-title headline text-bold">
            {{ $t(accordion.title) }}
          </div>
        </template>
        <template>
          <div
            class="content-wrapper"
            v-for="(accordionItem, index) in accordion.content"
            :key="index"
          >
            <div class="row content-title" v-if="accordionItem.title">
              <p class="col-12">
                {{ $t(accordionItem.title) }}
              </p>
            </div>
            <div class="content align-items-center row text-l">
              <p class="col-12 col-md-6" v-html="$t(accordionItem.l)" />
              <p class="col-12 col-md-6" v-html="$t(accordionItem.r)" />
            </div>
            <div
              v-if="index !== accordion.content.length - 1"
              class="divider"
            ></div>
          </div>
        </template>
      </accordion>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrizesAccordions",
  props: {},
  components: {},
  data: () => ({}),
  created() {},
  computed: {
    accordions() {
      return [
        {
          title: "pricesScreen.accordions.holoverz.title",
          content: [
            {
              l: "pricesScreen.accordions.holoverz.content.0.l",
              r: "pricesScreen.accordions.holoverz.content.0.r",
            },
            {
              l: "pricesScreen.accordions.holoverz.content.1.l",
              r: "pricesScreen.accordions.holoverz.content.1.r",
            },
            {
              l: "pricesScreen.accordions.holoverz.content.2.l",
              r: "pricesScreen.accordions.holoverz.content.2.r",
            }
          ],
        },
        {
          title: "pricesScreen.accordions.blocknote.title",
          content: [
            {
              l: "pricesScreen.accordions.blocknote.content.0.l",
              r: "pricesScreen.accordions.blocknote.content.0.r",
            },
            {
              l: "pricesScreen.accordions.blocknote.content.1.l",
              r: "pricesScreen.accordions.blocknote.content.1.r",
            },
            {
              l: "pricesScreen.accordions.blocknote.content.2.l",
              r: "pricesScreen.accordions.blocknote.content.2.r",
            },
          ],
        },
        {
          title: "pricesScreen.accordions.blocknotex.title",
          content: [
            {
              l: "pricesScreen.accordions.blocknotex.content.0.l",
              r: "pricesScreen.accordions.blocknotex.content.0.r",
            },
            {
              l: "pricesScreen.accordions.blocknotex.content.1.l",
              r: "pricesScreen.accordions.blocknotex.content.1.r",
            },
            {
              l: "pricesScreen.accordions.blocknotex.content.2.l",
              r: "pricesScreen.accordions.blocknotex.content.2.r",
            },
          ],
        },
        {
          title: "pricesScreen.accordions.blockstock.title",
          content: [
            {
              l: "pricesScreen.accordions.blockstock.content.0.l",
              r: "pricesScreen.accordions.blockstock.content.0.r",
            },
            {
              l: "pricesScreen.accordions.blockstock.content.1.l",
              r: "pricesScreen.accordions.blockstock.content.1.r",
            },
          ],
        },
        {
          title: "pricesScreen.accordions.blockben.title",
          content: [
            {
              l: "pricesScreen.accordions.blockben.content.0.l",
              r: "pricesScreen.accordions.blockben.content.0.r",
            },
            {
              l: "pricesScreen.accordions.blockben.content.1.l",
              r: "pricesScreen.accordions.blockben.content.1.r",
            },
            {
              l: "pricesScreen.accordions.blockben.content.2.l",
              r: "pricesScreen.accordions.blockben.content.2.r",
            },
            {
              title: "pricesScreen.accordions.blockben.content.3.title",
              l: "pricesScreen.accordions.blockben.content.3.l",
              r: "pricesScreen.accordions.blockben.content.3.r",
            },
            {
              title: "pricesScreen.accordions.blockben.content.4.title",
              l: "pricesScreen.accordions.blockben.content.4.l",
              r: "pricesScreen.accordions.blockben.content.4.r",
            },
            {
              l: "pricesScreen.accordions.blockben.content.5.l",
              r: "pricesScreen.accordions.blockben.content.5.r",
            },
            {
              l: "pricesScreen.accordions.blockben.content.6.l",
              r: "pricesScreen.accordions.blockben.content.6.r",
            },
            {
              l: "pricesScreen.accordions.blockben.content.7.l",
              r: "pricesScreen.accordions.blockben.content.7.r",
            },
            {
              l: "pricesScreen.accordions.blockben.content.8.l",
              r: "pricesScreen.accordions.blockben.content.8.r",
            },
          ],
        },
        {
          title: "pricesScreen.accordions.ebso.title",
          content: [
            {
              l: "pricesScreen.accordions.ebso.content.0.l",
              r: "pricesScreen.accordions.ebso.content.0.r",
            },
            {
              l: "pricesScreen.accordions.ebso.content.1.l",
              r: "pricesScreen.accordions.ebso.content.1.r",
            },
            {
              l: "pricesScreen.accordions.ebso.content.2.l",
              r: "pricesScreen.accordions.ebso.content.2.r",
            },
            {
              l: "pricesScreen.accordions.ebso.content.3.l",
              r: "pricesScreen.accordions.ebso.content.3.r",
            },
          ],
        },
      ];
    },
  },
  methods: {
    calcMaxHeight(i) {
      switch(i){
        case 0:
          return 1300
        case 1:
          return 1300
        case 2:
          return 1300
        case 3:
          return 1300
        case 4:
          return 1300
        case 5:
          return 1300
        case 6:
          return 1300
        case 7: 
          return 1300
        case 8:
          return 1300
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#prizes-welcome-component {
  padding: $margin-l 0 $margin-m 0;
  .accordion {
    background: #ffffff;
    border: 1px solid rgba(75, 46, 170, 0.2);
    border-radius: 10px;
    padding: 24px 35px 24px 65px;
    margin-bottom: $margin-s;
    transition: all 0.4s ease-in-out;
    &:last-of-type {
      margin-bottom: 0;
    }
    &.open::v-deep {
      box-shadow: 0px 4px 8px 0px #4b2eaa1a;
    }
    .content-wrapper {
      .content-title {
        font-weight: 700;
        margin-top: $margin-s;
        color: $text-blue;
      }
      .content {
        padding-top: $margin-s;
        padding-bottom: $margin-s;
      }
      .divider {
        border: 1px solid $primary-purple;
        width: 100%;
      }
    }
  }
}
@media (max-width: $breaking-point-to-sm) {
  .accordion {
    padding: 22px 10px 12px 10px !important;
    .header-title {
      font-size: $text-l !important;
    }
    .content-wrapper {
      .content {
        font-size: $text-m !important;
      }      
    }
  }
}
</style>
