<template>
  <div id="prices-screen">
    <jumbotron
      :title="$t('pricesScreen.welcomeComponent.title')"
      :description="$t('pricesScreen.welcomeComponent.description')"
      :background="'#FFFFFF'"
      imgSrc="/images/screens/fees/desktop/header_illustration.svg"
      mobileImgSrc="/images/screens/fees/mobile/header_illustration.svg"
      :imgBackgroundColor="'linear-gradient(211.57deg, rgba(75, 46, 170, 0.6) -0.96%, rgba(17, 239, 217, 0.6) 87.96%)'"
      :mobileHeight="605"
    />
    <prices-accordions />
    <prices-description />
    <prices-our-policy />
  </div>
</template>

<script>
import PricesAccordions from "@/components/modules/prices/PricesAccordions";
import PricesDescription from "@/components/modules/prices/PricesDescription";
import PricesOurPolicy from "@/components/modules/prices/PricesOurPolicy";

export default {
  name: "PricesScreen",
  props: {},
  components: {
    PricesAccordions,
    PricesDescription,
    PricesOurPolicy,
  },
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#prices-screen {
  padding-bottom: $margin-l;
}

.jumbotron {
 &::v-deep {
   .jumbotron-container {
      width:calc(50vw - 23px);

      @media (max-width: $breakpoint-sm) {
        height: auto;
        padding: 60px 15px 0;
      }
   }

   .illustration-background {
      @media (max-width: $breakpoint-sm) {
        width: 100%;
        position: relative;
        border-radius: 0;
      }
   }
 } 
}
</style>
